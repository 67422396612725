interface ImportMap {
  imports?: Record<string, string>;
}

export async function attachImportMap() {
  const importMap = await readImportMap();

  const script = document.createElement("script");
  script.type = "importmap";
  script.innerHTML = JSON.stringify(importMap);
  document.head.appendChild(script);
}

async function readImportMap(): Promise<ImportMap> {
  const map = (await parseImportMaps("script[type=fx-importmap]")).reduce(
    (prev, curr) => ({ imports: { ...prev.imports, ...curr.imports } }),
    {}
  );
  console.debug("Importmap: %o", map);
  return map;
}

async function parseImportMaps(query: string): Promise<Array<ImportMap>> {
  const scripts = [...document.head.querySelectorAll(query)] as Array<HTMLScriptElement>;
  return Promise.all(
    scripts.map(async (s) => {
      if (s.src) {
        const src = s.src;
        const map = await fetchImportMap(src);
        s.removeAttribute("src");
        s.setAttribute("data-src", src);
        s.textContent = JSON.stringify(map);
        return map;
      } else {
        return JSON.parse(s.textContent ?? "{}") as ImportMap;
      }
    })
  );
}

async function fetchImportMap(url: string): Promise<ImportMap> {
  const map = (await (await fetch(url)).json()) as ImportMap;
  const imports = map.imports;
  if (imports) {
    Object.entries(imports).forEach(([name, url]) => {
      if (!/(\.esm\.js|\.js\?esm)$/.test(url)) {
        delete imports[name];
      }
    });
  }
  return map;
}
